<template>
  <div>
    <input
      type="text"
      class="form-control"
      v-model="query"
      @input="onTyping"
    >
  </div>
</template>

<script>
  const _ = require('lodash')

  export default {
    data: () => ({
      isTyping: false,
      searched: false,
      query: ''
    }),
    watch: {
      query: _.debounce(function() {
        this.isTyping = false
        if (this.query.length < 2) {
          this.$emit('close')
        }
      }, 800),
      isTyping: async function(val) {
        if (!val && this.query.length >= 2) {
          this.$emit('result', this.query)
        }
      }
    },
    methods: {
      onTyping($e) {
        if (this.$store.state.app.isLoading) {
          $e.preventDefault()
        } else {
          this.isTyping = true
        }
      },
      clear() {
        this.query = ''
      }
    }
  }
</script>