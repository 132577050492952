<template>
  <div class="page-with-title">
    <page-title>
      <div class="row">
        <div class="col-3">
          <h1>Músicas</h1>
        </div>
        <div class="col-9">
          <SongsSearchInput
            @song-selected="songSelected"
            @result="showSearchResults"
            @close="hideSearchResults"
          />
        </div>
      </div>
    </page-title>
    <div class="p-4">
      <div class="row justify-content-center">
        <div class="col-8">
          <table class="table table-dark">
            <thead>
              <tr>
                <th>ID</th>
                <th>Título</th>
                <th>Artista</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="song in songs"
                :key="song.id_musica"
              >
                <td>{{ song.id_musica }}</td>
                <td>
                  <router-link :to="{ name: 'songShow', params: { id: song.id_musica } }">
                    {{ song.titulo }}
                  </router-link>
                </td>
                <td>
                  <router-link :to="{ name: 'artistShow', params: {id: song.cod_artista} }">
                    {{ song.artista }}
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="text-center mb-4">
      <button-next-page
        v-if="!oldSongs.length"
        @reached="page++; getSongs()"
      />
    </div>
  </div>
</template>

<script>
  import PageTitle from '@/components/titles/PageTitle'
  import ButtonNextPage from '@/components/utils/ButtonNextPage'
  import { getSongs } from '@/api/songs'
  import SongsSearchInput from '@/components/songs/SongsSearchInput'

  export default {
    components: {
      PageTitle,
      ButtonNextPage,
      SongsSearchInput
    },
    data: () => ({
      songs: [],
      oldSongs: [],
      page: 1,
      lastPage: null
    }),
    created() {
      this.getSongs()
    },
    methods: {
      async getSongs() {
        this.$store.commit('app/SET_LOADING', true)
        const res = await getSongs({ page: this.page })
        res.data.data.data.map(s => this.songs.push(s))
        this.$store.commit('app/SET_LOADING', false)
      },
      showSearchResults(songs) {
        if (!this.oldSongs.length) {
          this.oldSongs = this.songs
        }
        this.songs = songs
      },
      hideSearchResults() {
        this.songs = this.oldSongs
        this.oldSongs = []
      },
      songSelected (song) {
        this.$router.push({name: 'songShow', params: { id: song.id_musica } })
      }
    }
  }
</script>
