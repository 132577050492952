<template>
  <div class="search-box">
    <SearchInput
      ref="searchInput"
      @result="search"
      @close="hideSearchResults"
    />

    <div
      v-if="songsTips.length"
      class="options-box"
    >
      <div
        v-for="song in songsTips"
        :key="song.id_musica"
        class="option-item"
        @click="songSelected(song)"
      >
        <b>{{ song.titulo }}</b> - {{ song.artista }}
      </div>
    </div>
  </div>
</template>

<script>
  import SearchInput from '@/components/utils/SearchInput'
  import { searchSongs } from '@/api/songs'

  export default {
    components: {
      SearchInput
    },

    props: {
      songs: { type: Array, default: () => ([]) },
      artistId: { type: Number, default: null }
    },

    data: () => ({
      songsTips: []
    }),

    methods: {
      songSelected (song) {
        this.$emit('song-selected', song)
        this.clear()
      },

      async search(query) {
        const queryParams = {}

        if (this.artistId) {
          queryParams['id_artista'] = this.artistId
        }

        const songs = await searchSongs(query, {queryParams})
        this.showSearchResults(songs)
      },

      showSearchResults(songs) {
        this.songsTips = songs.filter(song => {
          return this.songs.map(p => p.id_musica).indexOf(song.id_musica) == -1
        })
      },

      hideSearchResults() {
        this.songsTips = []
      },

      clear() {
        this.hideSearchResults()
        this.$refs.searchInput.clear()
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "~@/styles/variables";

  .search-box {
    position: relative;
  }

  .options-box {
    position: absolute;
    z-index: 10;
    margin-top: 5px;
    width: 100%;
    background: $sideMenuBgColor;

    .option-item {
      padding: 8px 10px;
      font-size: 14px;
      color: rgba(#fff, .95);
      cursor: pointer;

      &:hover {
        background: lighten(#000, 20%);
      }
    }
  }
</style>